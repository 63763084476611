<template>
  <div class="culture">
    <div class="culture-container w1200">
      <div class="culture_title">
        <!-- tlttle1/title2 -->
        <!-- title3 小英文 -->
        <slot name="title1">
          <h3></h3>
        </slot>
        <slot name="title2">
          <h3></h3>
        </slot>
        <slot name="title3">
          <p></p>
        </slot>
      </div>
      <!-- <div class="culture_info">
        <div class="culture_info_top">
          <slot name="top_i"><i class="iconfont"></i></slot>
          <slot name="top_p1">
            <p></p>
          </slot>
          <slot name="top_sp1"><span></span></slot>
        </div>
        <div class="culture_info_bottom">
          <slot name="info_p1">
            <p></p>
          </slot>
        </div>
      </div>
      <div class="culture_info">
        <div class="culture_info_top">
          <slot name="top_i"><i class="iconfont"></i></slot>
          <slot name="top_p2">
            <p></p>
          </slot>
          <slot name="top_sp2"><span></span></slot>
        </div>
        <div class="culture_info_bottom">
          <slot name="info_p2">
            <p></p>
          </slot>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Culture'
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 0px) and (max-width: 1366px) {
    .culture{
        position: absolute;
        width: 100%;
        top: 10rem;
        display: flex;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1920px) {
    .culture{
        position: absolute;
        width: 100%;
        top: 10rem;
        display: flex;
    }
}
@media screen and (min-width: 1921px) and (max-width: 2560px) {
    .culture{
        position: absolute;
        width: 100%;
        top: 12rem;
        display: flex;
    }
}

@media screen and (min-width: 2561px) {
    .culture{
        position: absolute;
        width: 100%;
        top: 16rem;
        display: flex;
    }
}
.culture_title {
    margin-bottom: 1.25rem;
    font-weight: 800;
    color: #ffffff;
    line-height: 5rem;
    text-shadow: 0.125rem 0.125  0.125rem #55abed;
    margin-left: 15px;
}
.culture_title h3 {
    font-size: 4rem;
    line-height: 6.5rem;
    letter-spacing: 0.3125rem;
    font-weight: bold;
    font-style: italic;
}
.culture_title p {
    font-size: 2.25rem;
    line-height: 2.25rem;
    // font-weight: bold;
    font-style: italic;
}
.culture_info {
  margin: 0.9375rem 0;
  color: #ffffff;
}
.culture_info .culture_info_top p {
  font-size: 1.625rem;
  line-height: 1.875rem;
  display: inline;
}
.culture_info .culture_info_top i {
  margin-right: 0.3125rem;
  font-size: 0.75rem;
}
.culture_info .culture_info_top span {
  margin-left: 0.9375rem;
  font-size: 1.25rem;
  font-weight: 500;
  color: #bfe6ff;
  line-height: 1.5rem;
}
.culture_info .culture_info_bottom p {
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.875rem;
  margin: 0.625rem 0 0 1.25rem;
}
</style>
