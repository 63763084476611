<template>
    <div class="footer w100">
        <img src="../../assets/imgs/foot_back1.png" alt="" class="foot_all" />
        <div class="w1200">
            <div class="footer_container w1200">
                <img src="../../assets/imgs/call_me.png" alt="" class="title"/>
                <div class="foot_info">
                    <div class="imgs_container solid_container">
                        <p>联系电话</p>
                        <p>010-51722017</p>
                        <div class="solid"></div>
                    </div>
                    <div class="copy_right solid_container">
                        <p>公司地址</p>
                        <p>北京市海淀区海淀大街38号银科大厦9层</p>
                        <div class="solid"></div>
                    </div>
                    <div class="copy_bottom">
                        <p>公司网址</p>
                        <a href="www.labour-union.com.cn">www.labour-union.com.cn</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="baNum">
            <p>备案号：京ICP备2020040833号</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="less" scoped>
.footer {
    position: relative;
    //   height: 500px;
    >img{
        min-height: 200px;
    }
}
.foot_all {
    width: 100%;
}
.footer_container {
    position: absolute;
    top: 3rem;
    .foot_info{
        margin-top: 1rem;
        display: flex;
        justify-content: space-around;
        a,p{
            font-size: 1.25rem;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 2.375rem;
        }
        .solid_container{
            position: relative;
            .solid{
                position: absolute;
                top: 1.5625rem;
                right: -7.5rem;
                height: 1.875rem;
                border-right: 2px rgba(255, 255, 255, 0.4) solid;
            }
        }
    }
}
.baNum{
    font-size: 1rem;
    position: absolute;
    bottom: 30px;
    right: 45%;
    color: #ffffff;
    opacity: 0.6;
}
</style>
