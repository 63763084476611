<template>
    <div id="header" :class="header_bc">
        <div class="header w1200">
            <div class="logo">
                <h1>
                    <keep-alive>
                        <router-link to="/home">
                            <img src="../../assets/imgs/lbyn_logo.png" alt="雷博优尼" @click="changeTop()" title="雷博优尼" />
                            <h1 style="display: none">雷博优尼</h1>
                        </router-link>
                    </keep-alive>
                </h1>
            </div>
            <div class="right">
                <keep-alive>
                    <router-link to="/home" @click.native="changeTop()">
                        <span class="el-dropdown-link">&nbsp;&nbsp;首页&nbsp;&nbsp;</span>
                    </router-link>
                </keep-alive>
                <keep-alive>
                    <router-link to="/about" @click.native="changeTop()">
                        <el-dropdown>
                            <span class="el-dropdown-link">关于雷博</span>
                            <el-dropdown-menu slot="dropdown">
                                <a @click="gtoAbout1()">
                                    <el-dropdown-item> 公司介绍 </el-dropdown-item>
                                </a>
                                <a @click="gtoAbout2()">
                                    <el-dropdown-item> 发展历程 </el-dropdown-item>
                                </a>
                                <a @click="gtoAbout3()">
                                    <el-dropdown-item> 主营业务 </el-dropdown-item>
                                </a>
                                <a @click="gtoAbout4()">
                                    <el-dropdown-item> 组织架构 </el-dropdown-item>
                                </a>
                                <a @click="gtoAbout5()">
                                    <el-dropdown-item> 企业资质 </el-dropdown-item>
                                </a>
                                <a @click="gtoAbout6()">
                                    <el-dropdown-item> 合作伙伴 </el-dropdown-item>
                                </a>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </router-link>
                </keep-alive>
                <keep-alive>
                    <router-link to="/product" @click.native="changeTop()">
                        <el-dropdown>
                            <span class="el-dropdown-link">产品中心</span>
                            <el-dropdown-menu slot="dropdown">
                                <a @click="gtoProduct()">
                                    <el-dropdown-item> 产品名录 </el-dropdown-item>
                                </a>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </router-link>
                </keep-alive>
                <keep-alive>
                    <router-link to="/serve" @click.native="changeTop()">
                        <el-dropdown>
                            <span class="el-dropdown-link">服务支持</span>
                            <el-dropdown-menu slot="dropdown">
                                <a @click="gtoServe1()">
                                    <el-dropdown-item> 服务标准 </el-dropdown-item>
                                </a>
                                <a @click="gtoServe2()">
                                    <el-dropdown-item> 服务内容 </el-dropdown-item>
                                </a>
                                <a @click="gtoServe3()">
                                    <el-dropdown-item> 常见问题 </el-dropdown-item>
                                </a>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </router-link>
                </keep-alive>
                <keep-alive>
                    <router-link to="/client" @click.native="changeTop()">
                        <span class="el-dropdown-link">资讯中心</span>
                    </router-link>
                </keep-alive>
                <keep-alive>
                    <router-link to="/customer" @click.native="changeTop()">
                        <el-dropdown>
                            <span class="el-dropdown-link">典型客户</span>
                            <el-dropdown-menu slot="dropdown">
                                <a @click="gtoCustomer1()">
                                    <el-dropdown-item> 工惠通 </el-dropdown-item>
                                </a>
                                <a @click="gtoCustomer2()">
                                    <el-dropdown-item> 客户名录 </el-dropdown-item>
                                </a>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </router-link>
                </keep-alive>
            </div>
        </div>
    </div>
</template>

<script>
// import router from 'vue-router'
export default {
  name: 'Header',
  data () {
    return {
      header_bc: '',
      documentScrollTop: '' // 设置的监听屏幕top的变化
    }
  },
  created () {
    // 监听滚动条距离顶部距离>10，增加背景class
    window.addEventListener('scroll', this.throttle(this.realFunc, 100, 200))
    // f5刷新回到页面顶部
    window.onbeforeunload = function () {
      document.documentElement.scrollTop = 0 // ie下
      document.body.scrollTop = 0 // 非ie
    }
  },
  methods: {
    // 简单的节流函数
    throttle (func, wait, mustRun) {
      var timeout
      var startTime = new Date()
      return function () {
        var context = this
        var args = arguments
        var curTime = new Date()
        clearTimeout(timeout)
        // 如果达到了规定的触发时间间隔，触发 handler
        if (curTime - startTime >= mustRun) {
          func.apply(context, args)
          startTime = curTime
          // 没达到触发间隔，重新设定定时器
        } else {
          timeout = setTimeout(func, wait)
        }
      }
    },
    // 屏幕滚动超过10px。加class背景颜色
    realFunc () {
      var documentScrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (documentScrollTop > 10) {
        this.header_bc = 'header_bc'
      } else {
        this.header_bc = ''
      }
    },
    // 回到顶部
    changeTop () {
      document.documentElement.scrollTop = 0
    },
    gtoAbout1 () {
      if (this.$route.path !== '/about') {
        this.changeTop()
        this.$router.push('/about')
      }
      var element = document.getElementById('introduce')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gtoAbout2 () {
      if (this.$route.path !== '/about') {
        this.changeTop()
        this.$router.push('/about')
      }
      var element = document.getElementById('history')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gtoAbout3 () {
      if (this.$route.path !== '/about') {
        this.changeTop()
        this.$router.push('/about')
      }
      var element = document.getElementById('business')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gtoAbout4 () {
      if (this.$route.path !== '/about') {
        this.changeTop()
        this.$router.push('/about')
      }
      var element = document.getElementById('structure')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gtoAbout5 () {
      if (this.$route.path !== '/about') {
        this.changeTop()
        this.$router.push('/about')
      }
      var element = document.getElementById('partner')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gtoAbout6 () {
      if (this.$route.path !== '/about') {
        this.changeTop()
        this.$router.push('/about')
      }
      var element = document.getElementById('partner')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gtoProduct () {
      if (this.$route.path !== '/product') {
        this.changeTop()
        this.$router.push('/product')
      }
      var element = document.getElementById('product1')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gtoServe1 () {
      if (this.$route.path !== '/serve') {
        this.changeTop()
        this.$router.push('/serve')
      }
      var element = document.getElementById('standard')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
      //   setTimeout(function () {
      //     window.scrollTo(0, 350)
      //   }, 0)
    },
    gtoServe2 () {
      if (this.$route.path !== '/serve') {
        this.changeTop()
        this.$router.push('/serve')
      }
      var element = document.getElementById('service')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gtoServe3 () {
      if (this.$route.path !== '/serve') {
        this.changeTop()
        this.$router.push('/serve')
      }
      var element = document.getElementById('problem')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gtoCustomer1 () {
      if (this.$route.path !== '/customer') {
        this.changeTop()
        this.$router.push('/customer')
      }
      var element = document.getElementById('adv')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gtoCustomer2 () {
      if (this.$route.path !== '/customer') {
        this.changeTop()
        this.$router.push('/customer')
      }
      var element = document.getElementById('customer1')
      window.setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    }
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
#header {
    z-index: 1000;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 5rem;
    background-color: #ffffff;
}
.header_bc {
    background-color: #ffffff;
}
.header {
    display: flex;
    justify-content: space-between;
    display: flex;
    align-items: center;
}
.header .scrollTop {
    background-color: #ffffff;
    border-bottom: 0.0625rem solid #ebebeb;
}
.logo {
    width: 25%;
    margin-right: 3.125rem;
}
.logo h1 img {
    // width: 16.25rem;
    height: 4rem;
    // margin-top: 1.25rem;
}
.right {
    display: flex;
    justify-content: space-around;
    width: 70%;
}
.el-dropdown-link {
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 500;
    color: #000000;
}
.right .router-link-exact-active span {
    color: #00509b;
    background-size: 100%;
}
.right .router-link-exact-active {
    border-bottom: 3px #00509b solid;
}
.el-dropdown-menu {
    background-color: rgba(83, 83, 83, 0.8);
    border: 1px solid rgba(83, 83, 83, 0.8);
}
.el-dropdown-menu__item {
    color: #ffffff;
    margin: 5px 40px;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    text-align: center;
    a {
        color: #fff;
    }
}
/deep/.popper__arrow {
    border: none !important;
}
/deep/.popper__arrow::after {
    border-bottom-color: rgba(83, 83, 83, 0.8) !important;
    margin-left: -20px !important;
    top: -5px !important;
    border-width: 10px;
}
.el-dropdown-menu__item:not(.is-disabled):hover {
    color: #fff;
    border-bottom: 1px solid #fff;
    background-color: rgba(83, 83, 83, 0);
}
</style>
