<template>
    <div id="news">
        <!-- 开头板块 -->
        <div class="home_bg w100">
            <img src="../assets/imgs/top-client.jpg" alt="top-client.jpg" class="w100" />
            <Header></Header>
            <Culture>
                <h3 slot="title1">资讯中心</h3>
                <p slot="title3">NEWS</p>
            </Culture>
        </div>
        <!-- 内容部分 -->
        <div class="content w1200">
            <div class="content_top">
                <h5>{{ mainNewData.title }}</h5>
                <p>{{ mainNewData.content[0] }}</p>
            </div>
            <div class="content_container" :style="{ 'text-align': mainNewData.align === 'center' ? 'center' : 'left' }">
                <!-- 1 -->
                <div v-for="index in 25" :key="index">
                    <p v-if="mainNewData.content[index] > ''&& mainNewData.content[index] !== '1'"><strong v-if="mainNewData.align === 'left'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>{{ mainNewData.content[index] }}</p>
                    <div class="place" v-if="mainNewData.content[index] == '1'"></div>
                    <div v-if="mainNewData.pic[index - 1] > ''" class="content_imgs">
                        <img :src="require('../assets/imgs/news/' + mainNewData.pic[index - 1])"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer -->
        <Footer></Footer>
    </div>
</template>

<script>
import Culture from '../components/Culture'
import Header from '../components/Header'
import Footer from '../components/Footer/FootSec.vue'
export default {
  name: 'News',
  props: ['id', 'info'],
  data () {
    return {
      mainNewData:{
          id: 13,
          title: "智慧工会服务管理平台系统荣获“2020中国信息技术创新应用”大奖",
          times: "2020-06-30",
          url:'',
          pic: ['', '', '', '', '', '', '', ''],
          align: 'left',
          content: ['',''],
      }
    }
  },
  components: {
    Header,
    Culture,
    Footer
  },
  methods: {
  },
  created () {
  },
  mounted(){
    this.mainNewData = JSON.parse(this.info)
  }
}
</script>

<style scoped lang="less">
#news .content h5 {
    font-size: 42px;
    font-weight: 400;
    color: #333333;
    margin: 50px 0 50px 0;
}
.place{
  height: 1rem;
}
.content {
    margin-bottom: 50px;
    min-height: 300px;
}
.content .content_top {
    text-align: center;
}
.content .content_imgs {
    text-align: center;
}
.content p {
    font-size: 18px;
    font-weight: 400;
    color: #999999;
    line-height: 36px;
}
.content span {
    display: block;
    color: #666666;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
}
</style>
